.menu {
  top: 50%;
  margin-top: -10px;
  right: 20px;
  width: 28px;
  height: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 9999
}

.menu span {
  display: block;
  position: absolute;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  height: 3px;
  background-color: #fff
}

.menu span:nth-child(1) {
  top: 0
}

.menu span:nth-child(2), .menu span:nth-child(3) {
  top: 8px
}

.menu span:nth-child(4) {
  top: 16px
}

.menu_opened span:nth-child(1) {
  top: 8px;
  width: 0%;
  left: 50%
}

.menu_opened span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg)
}

.menu_opened span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.menu_opened span:nth-child(4) {
  top: 8px;
  width: 0%;
  left: 50%
}