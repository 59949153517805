$bg-color-1: #ebebeb;
$bg-color-2: #c3c3db;

@mixin animate() {
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

@mixin animate-hover($property) {
  -webkit-transform: scale($property);
  -ms-transform: scale($property);
  transform: scale($property);
}

@mixin animate-hover-color($property) {
  background-color: #3e444c;
  color: white;
  -webkit-transform: scale($property);
  -ms-transform: scale($property);
  transform: scale($property);
}
