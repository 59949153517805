@import "css/normalize.css";
@import "css/mixins";

.app {
  min-height: 100vh;
  height: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: 'Source Code Pro', Arial, sans-serif;
  overflow-x: hidden;

  &__container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .text {
    line-height: 1.55;
    font-weight: 400;
    color: #000000;
    margin-bottom: 30px;

    &_xl-b {
      font-size: 30px;
      font-weight: 600;
    }

    &_xl {
      font-size: 30px;
      margin-bottom: 75px;
    }

    &_lg {
      font-size: 24px;
    }
    &_lg-b{
      font-size: 24px;
      font-weight: 600;
    }

    &_md {
      font-size: 20px;
    }

    &_sm {
      font-size: 15px;
    }
  }


  & .link {
    color: #2727f2 !important;
    cursor: pointer;
  }

  & small {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.5;
  }

  & a {
    color: inherit !important;
    text-decoration: none !important;
  }

  & .button-section {
    display: flex;
    flex-direction: row;
  }

  & .fa-animate {
    cursor: pointer;
    font-size: 20px;
    margin-left: auto;
    color: gray !important;
    @include animate();

    &:hover {
      @include animate-hover(1.15);
      color: #555555 !important;
    }
  }

  & .error {
    opacity: 0.75;
    font-weight: bold;
    color: red;
  }

  & .input-error {
    border: 1px solid red;
  }

  & .hide {
    display: none;
  }

  .private-policy {
    background-color: $bg-color-2;
  }
}

@media (max-width: 750px) {
  .text {
    line-height: 1.45 !important;
    margin-bottom: 25px !important;

    &_xl-b {
      font-size: 28px !important;
    }

    &_xl {
      font-size: 28px !important;
      margin-bottom: 60px !important;
    }

    &_lg {
      font-size: 22px !important;
    }

    &_lg-b{
      font-size: 22px !important;
    }

    &_md {
      font-size: 18px !important;
    }

    &_sm {
      font-size: 13px !important;
    }
  }
}

@media (max-width: 550px) {
  .text {
    margin-bottom: 20px !important;

    &_xl-b {
      font-size: 24px !important;
    }

    &_xl {
      font-size: 24px !important;
      margin-bottom: 45px !important;
    }

    &_lg {
      font-size: 17px !important;
    }

    &_lg-b{
      font-size: 17px !important;
    }

    &_md {
      font-size: 15px !important;
    }

    &_sm {
      font-size: 12px !important;
    }
  }
}