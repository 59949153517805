.contacts-form {
  display: flex;
  flex-direction: column;

  &__content {
    flex-direction: column;
    width: 300px;
    margin: auto;
  }

  &__small {
    position: relative;
    top: -35px;
  }

  & button {
    color: #ffffff;
    background-color: #000000;
    text-align: center;
    height: 60px;
    border: 0 none;
    font-size: 16px;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-appearance: none;
    font-weight: 700;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }
}

@media (max-width: 350px) {
  .contacts-form {
    &__content {
      width: 80vw !important;
    }
  }
}