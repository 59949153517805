.scroll-to-top {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 60px;
  right: 50px;
  border-radius: 30px;
  font-size: 40px;
  height: 50px;
  width: 50px;
  z-index: 998;
  transition: all .7s;
  background-color: rgba(0, 0, 0, 0);
  color: #000900;
  border: 1px solid #000000;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  &:hover, &:active {
    border: 0;
    background-color: #2d2d2d;
    color: white;
  }
  &:active, &:focus {
    outline: none !important;
  }

  &_hide {
    opacity: 0;
    pointer-events: none;
  }
}


@media (max-width: 570px) {
  .scroll-to-top {
    bottom: 70px !important;
    right: 20px !important;
    font-size: 30px !important;
    height: 40px !important;
    width: 40px !important;
  }
}

@media (max-width: 400px) {
  .scroll-to-top {
    right: 10px !important;
  }
}
