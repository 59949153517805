@import "../../../css/mixins";

.page-404 {
  display: flex;
  height: 100vh;
  background-color: $bg-color-1;

  &__content {
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  & .btn {
    font-size: 20px;
    align-items: center;
    color: white !important;
    @include animate();

    &:hover {
      @include animate-hover(1.03)
    }
  }

  & .fa {
    font-size: 24px;
  }

  & .hit-the-floor {
    text-align: center;
    color: #fff;
    font-size: 100px;
    font-weight: bold;
    font-family: Helvetica;
    text-shadow:
      0 1px 0 #ccc,
      0 2px 0 #c9c9c9,
      0 3px 0 #bbb,
      0 4px 0 #b9b9b9,
      0 5px 0 #aaa,
      0 6px 1px rgba(0,0,0,.1),
      0 0 5px rgba(0,0,0,.1),
      0 1px 3px rgba(0,0,0,.3),
      0 3px 5px rgba(0,0,0,.2),
      0 5px 10px rgba(0,0,0,.25),
      0 10px 10px rgba(0,0,0,.2),
      0 20px 20px rgba(0,0,0,.15);

    &_mini {
        font-size: 40px !important;
      }
  }
}
