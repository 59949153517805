.t-cover__arrow-wrapper_animated {
  animation: t-arrow-bottom 1.7s infinite ease
}

.t-cover__arrow {
  position: absolute;
  width: 100%;
  z-index: 9;
  left: -0.5vw;
  display: flex;
}

.t-cover__arrow-wrapper {
  margin: 0 auto;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  cursor: pointer
}

.t-cover__arrow-wrapper:hover {
  opacity: .7
}

.t-cover__arrow-svg {
  fill: #fff;
}

@-webkit-keyframes t-arrow-bottom {
  0% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  55% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes t-arrow-bottom {
  0% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
  50% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  55% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px)
  }
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
  }
}


//@media screen and (max-width: 640px) {
//  .t-cover__arrow_mobile {
//    -moz-transform: scale(.7);
//    -ms-transform: scale(.7);
//    -webkit-transform: scale(.7);
//    -o-transform: scale(.7);
//    transform: scale(.7)
//  }
//
//  .t-cover__arrow {
//    bottom: 14px
//  }
//}