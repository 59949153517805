.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  color: white;
  font-weight: 500;
  z-index: 1000;

  &__container {
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__menu {
    width: 100%;
    display: flex;

    & .menu {
      margin: 0 20px 0 auto;
    }
  }

  &__logo {
    font-size: 24px;
    white-space: nowrap;
    margin-left: 20px;
  }

  &__content {
    display: flex;
    width: 100%;

    &-mobile {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: black;

      .header__link {
        margin-bottom: 20px;
      }

      .header__switch-lang {
        margin: 20px auto 0 auto;
      }
    }
  }

  &__links {
    margin: 0 auto 0 auto;
    cursor: pointer;
    flex: 5;
    text-align: center;

    &-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__link {
    clear: both;
    display: inline;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
  }

  &__switch-lang {
    margin: 0 0 0 auto;
    flex: 1;
  }

  &__lang {
    clear: both;
    padding-left: 15px;
    white-space: nowrap;
    cursor: pointer;
    opacity: 0.5;

    &_active {
      opacity: 1 !important;
    }
  }
}

@media screen and (max-width: 760px) {
  .header {
    position: absolute !important;
    background-color: rgba(0, 0, 0, 1);
  }
}

@media screen and (max-width: 480px) {
  .header__logo {
    font-size: 20px;
  }
}