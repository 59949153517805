.input-field {
  margin-bottom: 30px;

  & input, & textarea {
    width: 300px;
    border: 1px solid black;
  }

  & input {
    height: 50px;
  }

  & .text {
    margin-bottom: 10px;
  }
}

@media (max-width: 350px) {
  .input-field {
    & input, & textarea {
      width: 80vw !important;
    }
  }
}