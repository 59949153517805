@import "../../css/mixins";

.main-page {
  & .section-badge {
    height: 100vh;
    background-image: url('../../img/3d-server-serverroom.jpg');
    background-color: #040405;
    ackground-attachment: scroll;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    color: #fafafa !important;

    &__background {
      height: 100vh;
      background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }

    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__title {
      font-size: 58px;;
      line-height: 1.2;
      margin: 25px 0 74px 0;
      font-weight: 600;
    }

    & .text {
      color: white;
    }
  }

  & .arrow-row {
    top: -70px;
    position: relative;
  }

  & .section-about-us {
    background-color: $bg-color-2;
  }

  & .section-web-geo{
    background-color: $bg-color-2;

    &__list{
      padding-top: 10px;
      font-weight: 600;
    }
  }

  & .section-expertise {
    background-color: $bg-color-1;

    &__list {
      padding-top: 30px;
      font-weight: 600;
    }
  }

  & .section-form {
    background-color:  $bg-color-1;
  }
}

@media (max-width: 530px) {
  .section-badge__title {
    font-size: 50px !important;
  }
}

@media (max-width: 430px) {
  .section-badge__title {
    font-size: 40px !important;
  }
}